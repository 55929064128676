<template>
    <div class="form">
        <div class="background background_blue">
            <div class="background__image"></div>
        </div>

        <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
            <div class="content">
                <div class="content__container cooperation">
                    <div class="cooperation__title">{{ "c_subtitle_1" | localize }}</div>
                    <div class="cooperation__text">{{ "c_text" | localize }}</div>
                    
                    <form @submit.prevent>
                        <div class="cooperation__title">{{ "c_subtitle_2" | localize }}</div>

                        <div class="form__group">
                            <v-text-field
                                v-model="company"
                                @input="checkForm($v.company)"
                                @blur="nextElementfocus('company', 'company_full')"
                                @keyup.enter="$event.target.blur()"
                                :class="{ invalid: $v.company.$dirty && !$v.company.required }"
                                :label=" 'c_company' | localize"
                                :placeholder="'c_required' | localize"
                                :hint=" ($v.company.$dirty && !$v.company.required ? 'c_required' : 'empty') | localize"
                                ref="company"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-model="company_full"
                                @input="checkForm($v.company_full)"
                                @blur="nextElementfocus('company', 'inn')"
                                @keyup.enter="$event.target.blur()"
                                :class="{ invalid: $v.company_full.$dirty && !$v.company_full.required }"
                                :label=" 'c_company_full' | localize"
                                :placeholder="'c_required' | localize"
                                :hint=" ($v.company_full.$dirty && !$v.company_full.required ? 'c_required' : 'empty') | localize"
                                ref="company_full"
                                outlined
                            ></v-text-field>
                        </div>

                        <div class="form__group">
                            <v-text-field
                                v-model="inn"
                                v-mask.hide-on-empty="innMask"
                                @input="checkForm($v.inn)"
                                @blur="nextElementfocus('inn', 'city')"
                                @keyup.enter="$event.target.blur()"
                                :class="{ invalid: ($v.inn.$dirty && !$v.inn.required) || ($v.inn.$dirty && !$v.inn.minLength) || ($v.inn.$dirty && !$v.inn.maxLength)}"
                                :label="'c_inn' | localize"
                                :placeholder="'c_required' | localize"
                                :hint=" ($v.inn.$dirty && !$v.inn.required ? 'c_required' : 'empty') | localize"
                                ref="inn"
                                outlined
                            ></v-text-field>

                            <v-autocomplete
                                v-model="city"
                                :items="cities"
                                @change="checkForm($v.city)"
                                :class="{ invalid: $v.city.$dirty && !$v.city.required }"
                                item-text="name"
                                item-value="value"
                                cache-items
                                :label="'c_city' | localize"
                                :placeholder="'select' | localize"
                                ref="city"
                                outlined
                            ></v-autocomplete>
                        </div>

                        <div class="form__group">
                            <v-textarea
                                v-model="address"
                                :class="{ invalid: $v.address.$dirty && !$v.address.required }"
                                :hint="($v.address.$dirty && !$v.address.required ? 'c_required' : 'empty') | localize"
                                :label="'c_address' | localize"
                                ref="address"
                                outlined
                                auto-grow
                            ></v-textarea>
                        </div>

                        <div class="cooperation__title">{{ "c_subtitle_3" | localize }}</div>

                        <div class="form__group">
                            <v-text-field
                                v-model="name"
                                @input="checkForm($v.name)"
                                @blur="nextElementfocus('name', 'position')"
                                @keyup.enter="$event.target.blur()"
                                :class="{ invalid: $v.name.$dirty && !$v.name.required }"
                                :label=" 'c_name' | localize"
                                :placeholder="'c_required' | localize"
                                :hint=" ($v.name.$dirty && !$v.name.required ? 'c_required' : 'empty') | localize"
                                ref="name"
                                outlined
                            ></v-text-field>
                            
                            <v-text-field
                                v-model="position"
                                @input="checkForm($v.position)"
                                @blur="nextElementfocus('position', 'phone')"
                                @keyup.enter="$event.target.blur()"
                                :class="{ invalid: $v.position.$dirty && !$v.position.required }"
                                :label=" 'c_post' | localize"
                                :placeholder="'c_required' | localize"
                                :hint=" ($v.position.$dirty && !$v.position.required ? 'c_required' : 'empty') | localize"
                                ref="position"
                                outlined
                            ></v-text-field>
                        </div>

                        <div class="form__group">
                            <v-text-field
                                v-model="phone"
                                type="tel"
                                v-mask.hide-on-empty="phoneMask"
                                @input="checkForm($v.phone)"
                                class="v-input-phone"
                                :class="{invalid: $v.phone.$dirty && (!$v.phone.required || !$v.phone.minLength)}"
                                :label="'c_phone' | localize"
                                :placeholder="'c_required' | localize"
                                :hint="($v.phone.$dirty && !$v.phone.required ? 'c_required' : 'empty') | localize"
                                ref="phone"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-model="email"
                                type="email"
                                @input="checkForm($v.email)"
                                :class="{
                                    invalid:
                                    ($v.email.$dirty && !$v.email.required) ||
                                    ($v.email.$dirty && !$v.email.email),
                                }"
                                :label="'c_email' | localize"
                                placeholder="name@bankimonline.com"
                                :hint="hintArray(
                                    $v.email, [
                                    {rule: $v.email.required, message: 'form_reqiered_email'},
                                    {rule: $v.email.alpha, message: 'form_wrong_abc_email'},
                                    {rule: $v.email.email, message: 'form_wrong_email'}
                                    ]
                                ) | localize"
                                ref="email"
                                outlined
                            ></v-text-field>
                        </div>

                        <div class="form__group">
                            <v-text-field
                                v-model="link"
                                @input="checkForm($v.link)"
                                :class="{ invalid: $v.link.$dirty && !$v.link.required }"
                                :label=" 'c_link' | localize"
                                :placeholder="'https://company.com'"
                                :hint=" ($v.link.$dirty && !$v.link.required ? 'c_required' : 'empty') | localize"
                                ref="link"
                                outlined
                            ></v-text-field>
                        </div>

                        <div class="cooperation__title">{{ "c_subtitle_4" | localize }}</div>

                        <div class="form__group">
                            <v-textarea
                                v-model="text_1"
                                :class="{ invalid: $v.text_1.$dirty && !$v.text_1.required }"
                                :hint="($v.text_1.$dirty && !$v.text_1.required ? 'c_required' : 'empty') | localize"
                                :label="'c_text_1' | localize"
                                ref="text_1"
                                outlined
                                auto-grow
                            ></v-textarea>

                            <v-text-field
                                v-model="years"
                                @input="checkForm($v.years)"
                                :class="{ invalid: $v.years.$dirty && !$v.years.required }"
                                :label=" 'c_years' | localize"
                                :placeholder="'c_required' | localize"
                                :hint=" ($v.years.$dirty && !$v.years.required ? 'c_required' : 'empty') | localize"
                                ref="years"
                                outlined
                            ></v-text-field>
                        </div>

                        <div class="form__group">
                            <v-textarea
                                v-model="text_2"
                                :class="{ invalid: $v.text_2.$dirty && !$v.text_2.required }"
                                :hint="($v.text_2.$dirty && !$v.text_2.required ? 'c_required' : 'empty') | localize"
                                :label="'c_text_2' | localize"
                                ref="text_2"
                                outlined
                                auto-grow
                            ></v-textarea>
                        </div>

                        <div class="error__container error__container_button">
                            <p class="error" v-if="error">{{ error | localize }}</p>
                            <p class="error" v-if="!success && check_form">{{ 'field_required' | localize }}</p>
                        </div>

                        <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler()">
                            {{  'c_send' | localize }}
                        </button>
                    </form>
                </div>
            </div>
        </div>

        <div class="popup popup_active popup_info popup_lawyer" v-if="$store.state.popup.id">
            <div class="popup__overlay popup__overlay_active">
                <div class="popup__center" @click.self="$store.commit('togglePopupText')">
                    <div class="popup__window popup__window_blue">
                        <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
                        <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
                        <div class="popup__scrollcontainer ">
                            <div class="popup__scrollcontainer_inner">
                                <div class="text__body">
                                    {{ 'c_success_text' | localize }}
                                </div>
                            </div>
                        </div>
                        <div class="button__container" @click="$store.commit('togglePopupText')">
                            <router-link type="button" class="button button__form" :to="{name: 'Vacancy'}">{{ 'v_tooltip2' | localize }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { email, required, minLength, maxLength } from 'vuelidate/lib/validators'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import TextBlock from '@/components/Text.vue'

export default {
  name: 'Aspirant_Questionnaire',
  mixins: [formMixin, commonMixin, numberMixin],
  components: { TextBlock },
  data: () => ({
    cities: [],
    phoneMask: [/0/, /5|7/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    innMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    menu: false,

    company: null,
    company_full: null,
    inn: null,
    city: null,
    address: null,

    name: null,
    position: null,
    phone: null,
    email: null,
    link: null,

    text_1: null,
    text_2: null,
    years: null,

    error: null
  }),
  validations: {
    company: { required },
    company_full: { required },
    inn: { required, minLength: minLength(7), maxLength: maxLength(7) },
    city: { required },
    address: { required },

    name: { required },
    position: { required },
    phone: { required, minLength: minLength(12) },
    email: { required, email, alpha: val => /^[a-z]/i.test(val) },
    link: { required },

    text_1: { required },
    text_2: { required },
    years: { required }
  },
  mounted () {
    const localeName = 'name_' + this.$store.state.lang
    this.axios.get('cities?sort=population&projection=id,' + localeName).then((response) => {
      this.cities = response.data.map((e) => ({ name: e[localeName], value: e.id }))
    }).catch(() => { 
      this.error = 'server_error'
      this.$scrollTo('.error__container', 300, { offset: -400 })
    })
  },
  methods: {
    loadFile() {
      this.$refs.downloadFile.click()
    },
    fileChanged(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.resumeFile = files[0];
    },
    nextElementfocus (current, next) {
      this.$v[current].$touch()
      if (!this.$v[current].$invalid) {
        this.$refs[current].blur()
        setTimeout(() => { this.$refs[next].focus() }, 100)
      }
    },
    submitHandler () {
      this.check_form = true

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      const dataObj = {
        company: this.company,
        company_full: this.company_full,
        inn: this.parseNumber(this.inn),
        city_id: this.city,
        address: this.address,

        name: this.name,
        position: this.position,
        phone: this.phone,
        email: this.email,
        link: this.link,
        
        text_1: this.text_1,
        text_2: this.text_2,
        years: this.years
      }

      // Сохранение в бд
      this.$store.commit('loading', true)
      this.axios.post('cooperation', dataObj).then(() => {
        this.$store.commit('loading', false)
        this.$store.commit('togglePopupText', { id: 29 })
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data.error ?? 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }
  }
}
</script>